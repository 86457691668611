var ocWerb = {
    amazon: {
        pubID: '',
        adUnits: [],
        requested: false,
    },
    prebid: {
        bidderTimeout: 0,
        bidderConfig: {},
        bidderSettings: {},
        adUnits: [],
        requested: false,
    },
    requests: {
        prebid: false,
        aps: false,
        adserver: false,
    },
    width: 0,
    adUnits: [],
    responsiveBidders: [],
    adserverRequestSent: false,
    prebidFailed: false,
    bidderBack: function () {
        if (ocWerb.requests.prebid && ocWerb.requests.aps) {
            ocWerb.sendToAdserver();
        }
    },
    sendToAdserver: function () {
        if (ocWerb.requests.adserver === true) {
            return;
        }
        ocWerb.requests.adserver = true;
        googletag.cmd.push(function () {
            googletag.pubads().refresh();
        });
    },
    _log: function (message, extra) {
        try {
            window.qgLogger.error(message, extra);
        } catch (e) {
            // do nothing
        }
    },
    build: function () {
        try {
            ocWerb.width = ocWerb._getBrowserWidth();
            ocWerb._buildResponsiveAdTarget();
            ocWerb._buildResponsiveBidders();
            ocWerb._buildPb();
            if (ocWerb.amazon.pubID !== '') {
                ocWerb._buildAms();
            }
            ocWerb._requestBids();
            setTimeout(function () {
                ocWerb.sendToAdserver();
            }, ocWerb.prebid.bidderTimeout);
        } catch (e) {
            ocWerb.prebidFailed = true;
            ocWerb.sendToAdserver();
            ocWerb._log('ocWerb exception build', e);
        }
    },
    addAd: function (gptName, gptDiv, supportedSizes) {
        var finalSizes = [];
        var sizesList = [];
        for (var supportedSize = 0; supportedSize < supportedSizes.length; ++supportedSize) {
            if (typeof finalSizes[supportedSizes[supportedSize][0][0]] === "undefined") {
                finalSizes[supportedSizes[supportedSize][0][0]] = [];
            }
            finalSizes[supportedSizes[supportedSize][0][0]].push(supportedSizes[supportedSize]);
            if(sizesList.indexOf(supportedSizes[supportedSize][0][0]) === -1) {
                sizesList.push(supportedSizes[supportedSize][0][0]);
            }
        }
        ocWerb.adUnits.push({
            div: gptDiv,
            name: gptName,
            supportedSizes: supportedSizes,
            sizesList: sizesList,
            bidder: [],
        });
    },
    addBidder: function (gptDiv, hbConfig) {
        ocWerb.adUnits[ocWerb._getAdUnitByDiv(gptDiv)].bidder.push(hbConfig);
    },
    addBidderResponsive: function (size, hbConfig) {
        ocWerb.responsiveBidders.push({
            size: size,
            bidder: hbConfig,
        });
    },
    _getAdUnitByDiv: function (value) {
        for (var idx = 0; idx < ocWerb.adUnits.length; ++idx) {
            if (ocWerb.adUnits[idx].div === value) {
                return idx;
            }
        }
    },
    _getResponsiveBiddersBySize: function (targetSize) {
        var matchedResponsiveBidders = [];
        targetSize.forEach((size) => {
            for (var idx = 0; idx < ocWerb.responsiveBidders.length; ++idx) {
                if (
                    ocWerb.responsiveBidders[idx].size[0] === size[0] &&
                    ocWerb.responsiveBidders[idx].size[1] === size[1]
                ) {
                    matchedResponsiveBidders.push(ocWerb.responsiveBidders[idx]);
                }
            }
        })
        return matchedResponsiveBidders;
    },
    _buildResponsiveBidders: function () {
        for (var adIdx = 0; adIdx < ocWerb.adUnits.length; ++adIdx) {
            var responsiveBidders = ocWerb._getResponsiveBiddersBySize(ocWerb.adUnits[adIdx].targetSizes);
            for (var bidderIdx = 0; bidderIdx < responsiveBidders.length; ++bidderIdx) {
                ocWerb.adUnits[adIdx].bidder.push(responsiveBidders[bidderIdx].bidder);
            }
        }
    },
    _buildPb: function () {
        ocWerb._buildPbAdUnits();
        ocWerb._buildPbBidderSettings();
        ocWerb._buildPbConfig();
        if (typeof pbjs !== 'undefined') {
            pbjs = pbjs || {};
            pbjs.que = pbjs.que || [];
            pbjs.bidderSettings = ocWerb.prebid.bidderSettings;
            pbjs.setConfig(ocWerb.prebid.bidderConfig);
        }
    },
    _buildAms: function () {
        ocWerb._buildAmsAdUnits();

        apstag.init({
            pubID: ocWerb.amazon.pubID,
            adServer: 'googletag',
        });
    },
    _buildAmsAdUnits: function () {
        for (var ocAdIndex = 0; ocAdIndex < ocWerb.adUnits.length; ++ocAdIndex) {
            ocWerb.amazon.adUnits.push({
                slotID: ocWerb.adUnits[ocAdIndex].div,
                sizes: ocWerb.adUnits[ocAdIndex].targetSizes,
                slotName: ocWerb.adUnits[ocAdIndex].name,
            });
        }
    },
    _requestBids: function () {
        if (ocWerb.amazon.pubID !== '') {
            apstag.fetchBids(
                {
                    slots: ocWerb.amazon.adUnits,
                    timeout: ocWerb.prebid.bidderTimeout,
                },
                function (bids) {
                    googletag.cmd.push(function () {
                        apstag.setDisplayBids();
                        ocWerb.requests.aps = true;
                        ocWerb.bidderBack();
                    });
                }
            );
        } else {
            ocWerb.bidderBack();
        }
        pbjs.que.push(function () {
            pbjs.addAdUnits(ocWerb.prebid.adUnits);
            pbjs.requestBids({
                bidsBackHandler: function () {
                    googletag.cmd.push(function () {
                        pbjs.setTargetingForGPTAsync();
                        ocWerb.requests.prebid = true;
                        ocWerb.bidderBack();
                    });
                },
            });
        });
    },
    setPbTimeout: function (timeout) {
        ocWerb.prebid.bidderTimeout = timeout;
    },
    setAmazonId: function (pubID) {
        ocWerb.amazon.pubID = pubID;
    },
    _buildPbAdUnits: function () {
        for (var ocAdIndex = 0; ocAdIndex < ocWerb.adUnits.length; ++ocAdIndex) {

            ocWerb.prebid.adUnits.push({
                code: ocWerb.adUnits[ocAdIndex].div,
                mediaTypes: {
                    banner: {
                        sizes: ocWerb.adUnits[ocAdIndex].targetSizes,
                    },
                },
                bids: ocWerb.adUnits[ocAdIndex].bidder,
            });

        }
    },
    _buildPbConfig: function () {
        var customPrice = {
            buckets: [
                {
                    precision: 2,
                    min: 0,
                    max: 1,
                    increment: 0.05,
                },
                {
                    precision: 2,
                    min: 1,
                    max: 5,
                    increment: 0.1,
                },
                {
                    precision: 2,
                    min: 5,
                    max: 20,
                    increment: 0.5,
                },
            ],
        };
        ocWerb.prebid.bidderConfig = {
            enableSendAllBids: true,
            userSync: {
                iframeEnabled: true,
                filterSettings: {
                    iframe: {
                        bidders: ['openx'],
                        filter: 'include',
                    },
                },
            },
            priceGranularity: customPrice,
            bidderTimeout: ocWerb.prebid.bidderTimeout,
        };
    },
    _buildPbBidderSettings: function () {
        ocWerb.prebid.bidderSettings = {
            sovrn: {
                bidCpmAdjustment: function (bidCpm) {
                    return bidCpm * 0.95;
                },
            },
            appnexus: {
                bidCpmAdjustment: function (bidCpm) {
                    return bidCpm * 0.95;
                },
            },
            openx: {
                alwaysUseBid: true,
            },
        };
    },
    _buildResponsiveAdTarget: function () {
        for (var index = 0; index < ocWerb.adUnits.length; ++index) {
            ocWerb.adUnits[index]['target'] = 0;
            for (let size of ocWerb.adUnits[index].sizesList) {
                if (ocWerb.width >= size) {
                    ocWerb.adUnits[index]['target'] = size;
                    break;
                }

            }
            ocWerb.adUnits[index].targetSizes = [];
            ocWerb.adUnits[index].supportedSizes.forEach((item) => {
                if (item[0][0] === ocWerb.adUnits[index].target) {
                    ocWerb.adUnits[index].targetSizes.push(item[1]);
                }
            })
        }
    },
    _getBrowserWidth: function () {
        return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth || 1000;
    },
};

(function() {
    var _log = function(message, extraData) {
        try {
            window.qgLogger.log(message, extraData);
        } catch (e) {}
    };

    function checkA() {
        var data = {
            gtag: false,
            adsby: false,
            pb: false,
            jq: false,
            ashown: false,
            au: 0,
            pb_resp: 0,
            pb_target: 0,
            pb_render: 0,
            pb_cpm: [],
            pb_t: [],
            pb_failed: true,
            werb: false,
            tainted: false
        };

        if (window.jQuery) {
            data.jq = true;
        }

        if (typeof googletag !== 'undefined' && typeof googletag.getVersion === 'function') {
            data.gtag = true;
        } else {
            data.tainted = true;
        }

        if (typeof pbjs !== 'undefined' && typeof pbjs.version === 'string') {
            data.pb = true;
        } else {
            data.tainted = true;
        }

        if (typeof ocWerb !== 'undefined' && typeof ocWerb.prebidFailed === 'boolean') {
            data.pb_failed = ocWerb.prebidFailed;
        } else {
            data.pb_failed = true;
        }

        if (typeof ocWerb === 'object') {
            data.werb = true;
            data.au = ocWerb.adUnits.length;
        } else {
            data.tainted = true;
        }

        if (
            (data.jq === true && data.tainted === false) ||
            (typeof ocWerb === 'object' && ocWerb.prebidFailed === true)
        ) {
            $.each(ocWerb.adUnits, function(index, value) {
                if ($('#' + value.div).height() > 1) {
                    data.ashown = true;
                } else {
                    if (
                        $('#' + value.div)
                            .parent()
                            .hasClass('hidden-lg') === false
                    ) {
                        data.tainted = true;
                    } else {
                        data.au--;
                    }
                }
            });
        }

        if (data.tainted === false && data.pb === true) {
            data.pb_resp = Object.keys(pbjs.getBidResponses()).length;
        }

        if (data.tainted === false && data.pb === true) {
            data.pb_resp = Object.keys(pbjs.getBidResponses()).length;
        }

        if (data.tainted === false && data.jq === true && data.pb === true) {
            var responses = pbjs.getBidResponses();
            for (var adunit in responses) {
                if (responses.hasOwnProperty(adunit)) {
                    var bids = responses[adunit].bids;
                    for (var i = 0; i < bids.length; i++) {
                        var b = bids[i];
                        data.pb_cpm.push({ c: b.cpm, n: b.bidder });
                        data.pb_t.push({ t: b.timeToRespond, n: b.bidder });

                        if (typeof b.status !== 'undefined') {
                            if (b.status === 'targetingSet') {
                                data.pb_target++;
                            }

                            if (b.status === 'rendered') {
                                data.pb_render++;
                            }
                        }
                    }
                }
            }
        }
        _log('ocCheck', data);
    }

    function checkB() {
        var data = {
            gtag: false,
            adsby: false,
            pb: false,
            jq: false,
            ashown: false,
            au: 0,
            pb_resp: 0,
            pb_target: 0,
            pb_render: 0,
            pb_cpm: [],
            pb_t: [],
            pb_failed: true,
            werb: false,
            tainted: false
        };

        if (window.jQuery) {
            data.jq = true;
        }

        if (typeof googletag !== 'undefined' && typeof googletag.getVersion === 'function') {
            data.gtag = true;
        } else {
            data.tainted = true;
        }

        if (typeof epbjs !== 'undefined' && typeof epbjs.version === 'string') {
            data.pb = true;
            data.pb_failed = false;
        } else {
            data.tainted = true;
        }

        data.au = ezUnits.length;

        if (
            (data.jq === true && data.tainted === false)
        ) {
            $.each(ezUnits, function(index, value) {
                if ($('#ezoic-pub-ad-placeholder-' + value).height() > 1) {
                    data.ashown = true;
                } else {
                    if (
                        $('#ezoic-pub-ad-placeholder-' + value)
                            .parent()
                            .hasClass('hidden-lg') === false
                    ) {
                        data.tainted = true;
                    } else {
                        data.au--;
                    }
                }
            });
        }

        if (data.tainted === false && data.pb === true) {
            data.pb_resp = Object.keys(window.epbjs.getBidResponses()).length;
        }

        if (data.tainted === false && data.pb === true) {
            data.pb_resp = Object.keys(window.epbjs.getBidResponses()).length;
        }

        if (data.tainted === false && data.jq === true && data.pb === true) {
            var responses = window.epbjs.getBidResponses();
            for (var adunit in responses) {
                if (responses.hasOwnProperty(adunit)) {
                    var bids = responses[adunit].bids;
                    for (var i = 0; i < bids.length; i++) {
                        var b = bids[i];
                        data.pb_cpm.push({ c: b.cpm, n: b.bidder });
                        data.pb_t.push({ t: b.timeToRespond, n: b.bidder });

                        if (typeof b.status !== 'undefined') {
                            if (b.status === 'targetingSet') {
                                data.pb_target++;
                            }

                            if (b.status === 'rendered') {
                                data.pb_render++;
                            }
                        }
                    }
                }
            }

            if (data.tainted === false && data.jq === true && data.pb === true) {
                var responses = window.epbjs.getAllWinningBids();
                for (var adunit in responses) {
                    if (responses.hasOwnProperty(adunit)) {
                        var bids = responses[adunit].bids;
                        for (var i = 0; i < bids.length; i++) {
                            if (typeof b.status !== 'undefined') {
                                if (b.status === 'targetingSet') {
                                    data.pb_target++;
                                }
                                if (b.status === 'rendered') {
                                    data.pb_render++;
                                }
                            }
                        }
                    }
                }
            }
        }
        _log('ocCheck', data);
    }

    window.ocCheck = {
        checkA: checkA,
        checkB: checkB,
    };
})();

if (sat.ezoic !== true) {
    setTimeout(() => {window.ocCheck.checkA()}, 5000)
} else {
    setTimeout(() => {window.ocCheck.checkB()}, 5000)
}

export { ocWerb };
