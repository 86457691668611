import { ocWerb } from '../../qaamgo/modules/sat/ads/oc-ad-prebid-amazon.js';
import '../../vendor/prebid-6.6.0/prebid6.6.0.js';

import { globalLogger } from '../../qaamgo/helper/global-logger';
import { satGlobals } from '../../qaamgo/sat/helper/sat-globals';
import {projectHelper} from "../../lib-sat/misc/project-helper";

window.ocWerb = ocWerb;

function isMobileSlow() {
    if (navigator.connection && navigator.connection.effectiveType) {
        if (navigator.connection.effectiveType === '4g') {
            return false;
        } else {
            return true;
        }
    }
    return false;
}

function getCookieValue(name) {
    try {
        name = name.toLowerCase();
        var rawCookieString = document.cookie;

        var parts = rawCookieString.split(';');

        for (var i = 0; i < parts.length; i++) {
            var p = parts[i].split('=');

            if (p.length === 2) {
                var key = p[0].trim().toLowerCase();
                var value = p[1].trim();

                if (key === name) {
                    return value;
                }
            }
        }
    } catch (e) {}

    return null;
}

/**
 * Ads are not shown for:
 * - paying customers
 * - slow mobile phones
 *
 * @returns {boolean}
 */
function shouldAdsBeShown() {
    let playw = sessionStorage.getItem('PLAYWRIGHT');
    return getCookieValue('qgrole') !== 'premium' && isMobileSlow() === false && sessionStorage.getItem('PLAYWRIGHT') !== 'true';
}

/**
 * @param {String} slotName
 * @param {String} postConversionPrefix
 * @returns {String}
 */
function applyPostConversionPrefix(slotName, postConversionPrefix) {
    if (!slotName.includes('post_conversion')) {
        globalLogger.log('oc ads - post_conversion not found in slot name');
    }

    return slotName.replace(/post_conversion/, postConversionPrefix + '_post_conversion');
}

/**
 * @param {Array} adData
 * @param {Object} adConfig
 * @param {Boolean} adConfig.applyPostConversionPrefix
 * @param {String} [postConversionPrefix]
 */
function setupGoogleAdsNormal(adData, adConfig, postConversionPrefix) {
    if (window.sat.ezoic === true) {
        return;
    }

    if (window.sat.softonic === true) {
        return;
    }

    var n = adData.length;

    for (var didx = 0; didx < n; didx++) {
        if (adData[didx] !== null) {
            var datum = adData[didx];
            var sizeMappings = datum.sizeMappings;
            var nm = sizeMappings.length;

            var mapping = googletag.sizeMapping();

            for (var midx = 0; midx < nm; midx++) {
                mapping.addSize(sizeMappings[midx].browser, sizeMappings[midx].sizes);
            }

            var builtSizes = mapping.build();

            let slotName = datum.slot;

            if (adConfig.applyPostConversionPrefix && postConversionPrefix) {
                slotName = applyPostConversionPrefix(slotName, postConversionPrefix);
            }

            var slot = googletag.defineSlot(datum.slot, datum.supportedSizes, datum.container);

            slot.defineSizeMapping(builtSizes);

            if (datum.customChannel) {
                slot.set('adsense_channel_ids', datum.customChannel);
            }

            slot.addService(googletag.pubads());
        }
    }
    // {# TODO this is not used? #}
    // {#{% set locale = app.request.locale %}#}
    // {#{% if dfp.ads.custom_channel.lang[locale] is defined %}#}
    // {#        googletag.pubads().set('adsense_channel_ids', '{{ dfp.ads.custom_channel.lang[locale] }}');#}
    // {#{% endif %}#}

    googletag.pubads().enableSingleRequest();
    googletag.enableServices();

    function showAd(container) {
        googletag.cmd.push(function () {
            googletag.display(container.container);
        });
    }

    for (var sidx = 0; sidx < n; sidx++) {
        showAd(adData[sidx]);
    }
}

/**
 * @param {Array} adData
 * @param {Object} adConfig
 * @param {Boolean} adConfig.applyPostConversionPrefix
 * @param {Boolean} adConfig.headerBidding
 * @param {Object} adConfig.headerBiddingData
 * @param {String} adConfig.amazonId
 * @param {String} [postConversionPrefix]
 */
function setupHeaderBidding(adData, adConfig, postConversionPrefix) {

    if (window.sat.ezoic === true) {
        return;
    }

    if (window.sat.softonic === true) {
        return;
    }

    googletag.cmd.push(function () {
        if (getCookieValue('qg_consent') === 'true') {
            googletag.pubads().setRequestNonPersonalizedAds(0);
        } else {
            googletag.pubads().setRequestNonPersonalizedAds(1);
        }
    });

    ocWerb.setAmazonId(adConfig.amazonId);

    ocWerb.setPbTimeout(1150);

    adData.forEach((datum) => {
        let slotName = datum.slot;

        if (adConfig.applyPostConversionPrefix && postConversionPrefix) {
            slotName = applyPostConversionPrefix(slotName, postConversionPrefix);
        }

        const mappings = datum.sizeMappings;

        let sizes = [];

        mappings.forEach((sizeDatum) => {
            const _sizeBrowser = sizeDatum.browser;
            const _sizes = sizeDatum.sizes;
            if (_sizeBrowser[0] > 0) {
                _sizes.forEach((__size) => {
                    sizes.push([_sizeBrowser, __size]);
                });
            }
        });

        ocWerb.addAd(slotName, datum.container, sizes);
    });

    /** @type {Array} */
    const appnexusData = adConfig.headerBiddingData['APPNEXUS'];

    /** @type {Array} */
    const criteoData = adConfig.headerBiddingData['CRITEO'];

    /** @type {Array} */
    const sovrnData = adConfig.headerBiddingData['SOVRN'];

    appnexusData.forEach((datum) => {
        ocWerb.addBidderResponsive(datum.size, {
            bidder: 'appnexus',
            params: {
                placementId: datum.id,
            },
        });
    });

    criteoData.forEach((datum) => {
        ocWerb.addBidderResponsive(datum.size, {
            bidder: 'criteo',
            params: {
                zoneId: datum.id,
            },
        });
    });

    sovrnData.forEach((datum) => {
        ocWerb.addBidderResponsive(datum.size, {
            bidder: 'sovrn',
            params: {
                tagid: datum.id,
            },
        });
    });

    googletag.cmd.push(function () {
        var n = adData.length;

        for (var didx = 0; didx < n; didx++) {
            if (adData[didx] !== null) {
                var datum = adData[didx];
                var sizeMappings = datum.sizeMappings;
                var nm = sizeMappings.length;

                var mapping = googletag.sizeMapping();

                for (var midx = 0; midx < nm; midx++) {
                    mapping.addSize(sizeMappings[midx].browser, sizeMappings[midx].sizes);
                }

                var builtSizes = mapping.build();

                let slotName = datum.slot;

                if (adConfig.applyPostConversionPrefix && postConversionPrefix) {
                    slotName = applyPostConversionPrefix(slotName, postConversionPrefix);
                }

                var slot = googletag.defineSlot(slotName, datum.supportedSizes, datum.container);

                if (slot !== null) {
                    slot.defineSizeMapping(builtSizes);

                    if (datum.customChannel) {
                        slot.set('adsense_channel_ids', datum.customChannel);
                    }

                    slot.addService(googletag.pubads());
                }
            }

            googletag.pubads().disableInitialLoad();
            googletag.pubads().enableSingleRequest();
            googletag.pubads().setCentering(true);
            googletag.enableServices();
        }
    });

    ocWerb.build();
}

// this prevents double loading
let isInitialized = false;

// this is called from JS on the result page
window.startAds = function (postConversionPrefix) {

    if (isInitialized) {
        return;
    }

    if (window.sat.ezoic === true) {
        return;
    }

    if (window.sat.softonic === true) {
        return;
    }

    if (satGlobals.getProjectName() !== 'Online-Convert') {
        return;
    }

    isInitialized = true;

    if (!shouldAdsBeShown()) {
        return;
    }

    const adData = window.qgaddata();
    const adConfig = window.qgadconfig();

    if (adConfig.headerBidding) {
        setupHeaderBidding(adData, adConfig, postConversionPrefix);
    } else {
        googletag.cmd.push(function () {
            setupGoogleAdsNormal(adData, adConfig, postConversionPrefix);
        });
    }
};

function waitAndInitializeAds() {

    if (isInitialized) {
        return;
    }

    if (window.sat.ezoic === true) {
        return;
    }

    if (window.sat.softonic === true) {
        return;
    }

    if (!shouldAdsBeShown()) {
        return;
    }

    if (typeof window.qgaddata !== 'function' || typeof window.qgadconfig !== 'function') {
        setTimeout(waitAndInitializeAds, 500);

        return;
    }

    const adData = window.qgaddata();
    const adConfig = window.qgadconfig();

    // in this case the JS from the result page is calling window.startAds()
    // which takes care of everything
    if (adConfig.applyPostConversionPrefix) {
        return;
    }

    if (adConfig.headerBidding) {
        setupHeaderBidding(adData, adConfig);
    } else {
        googletag.cmd.push(function () {
            setupGoogleAdsNormal(adData, adConfig);
        });
    }
}

if (window.sat.ezoic !== true) {

    waitAndInitializeAds();
}
